var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemData &&
      (_vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Patrol ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.finalizePatrol)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      typeof _vm.itemData.status ===
                                        "undefined" ||
                                      _vm.itemData.status === "New"
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Client",
                                                    "label-for": "clientname"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "clientname",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.clientOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "clientname",
                                                                  disabled:
                                                                    _vm.theClientSite !==
                                                                    null,
                                                                  loading: !_vm
                                                                    .clientOptions
                                                                    .length,
                                                                  placeholder: !_vm
                                                                    .clientOptions
                                                                    .length
                                                                    ? "Loading..."
                                                                    : "Select Client"
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.handleSelectClient
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .clientname,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "clientname",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.clientname"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Client",
                                                    "label-for": "clientname"
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.itemData.clientname
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      typeof _vm.itemData.status ===
                                        "undefined" ||
                                      _vm.itemData.status === "New"
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Site",
                                                    "label-for": "sitename"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "sitename",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.siteOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "sitename",
                                                                  disabled:
                                                                    _vm.theClientSite !==
                                                                    null,
                                                                  loading: !_vm
                                                                    .siteOptions
                                                                    .length,
                                                                  placeholder: !_vm
                                                                    .siteOptions
                                                                    .length
                                                                    ? "Loading..."
                                                                    : "Select Site"
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.handleSelectSite
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .sitename,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "sitename",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.sitename"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Site",
                                                    "label-for": "sitename"
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.itemData.sitename
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      typeof _vm.itemData.status ===
                                        "undefined" ||
                                      _vm.itemData.status === "New"
                                        ? _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Patrol Name",
                                                    "label-for": "patrolname"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "patrolname",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("v-select", {
                                                                attrs: {
                                                                  dir: _vm
                                                                    .$store
                                                                    .state
                                                                    .appConfig
                                                                    .isRTL
                                                                    ? "rtl"
                                                                    : "ltr",
                                                                  options:
                                                                    _vm.patrolnameOptions,
                                                                  reduce: function(
                                                                    val
                                                                  ) {
                                                                    return val.value
                                                                  },
                                                                  clearable: false,
                                                                  "input-id":
                                                                    "patrolname",
                                                                  disabled:
                                                                    _vm.theClientSite !==
                                                                    null,
                                                                  loading: !_vm
                                                                    .patrolnameOptions
                                                                    .length,
                                                                  placeholder: !_vm
                                                                    .patrolnameOptions
                                                                    .length
                                                                    ? "Loading..."
                                                                    : "Select Patrol Template"
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.handleSelectPatrolTemplate
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .patrolname,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "patrolname",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.patrolname"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Patrol Name",
                                                    "label-for": "patrolname"
                                                  }
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.itemData.patrolname
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                    ],
                                    1
                                  ),
                                  _vm.thePatrolTemplate &&
                                  typeof _vm.itemData.status !== "undefined" &&
                                  _vm.itemData.status === "New"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.handleStartPatrol(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Start Patrol "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.theCheckpoints
                                    ? _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Incomplete Checkpoints")
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.thePatrolTemplate &&
                                  typeof _vm.itemData.status !== "undefined" &&
                                  _vm.itemData.status === "Started"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "warning",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.handlePausePatrol(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Pause Patrol "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.thePatrolTemplate &&
                                  typeof _vm.itemData.status !== "undefined" &&
                                  _vm.itemData.status === "Paused"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "warning",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.handleResumePatrol(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Resume Patrol "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.theCheckpoints
                                    ? _c(
                                        "b-row",
                                        [
                                          _vm.thePatrolTemplate.patrol_in_order
                                            ? _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _vm._v(
                                                    " You MUST complete the below Checkpoints in order. "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "b-col",
                                                { attrs: { md: "12" } },
                                                [
                                                  _vm._v(
                                                    " The below Checkpoints do NOT need to be completed in order. "
                                                  )
                                                ]
                                              )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-modal",
                                    {
                                      attrs: {
                                        id: "checkpoint-modal",
                                        centered: "",
                                        "hide-footer": "",
                                        title:
                                          _vm.checkpointDetailsModal.name +
                                          " Checkpoint"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.checkpointDetailsModal
                                                    .point_description
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-1 mb-1" },
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _vm.checkpointDetailsModal
                                                .docs_attachment_list
                                                ? _vm._l(
                                                    _vm.checkpointDetailsModal
                                                      .docs_attachment_list,
                                                    function(attachment) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.showImages,
                                                              expression:
                                                                "showImages"
                                                            }
                                                          ],
                                                          key:
                                                            attachment.access_url,
                                                          staticClass:
                                                            "item-img text-center"
                                                        },
                                                        [
                                                          _c(
                                                            "thumbs-lazy-image-component",
                                                            {
                                                              staticClass:
                                                                "card-img-top",
                                                              attrs: {
                                                                src:
                                                                  attachment.access_url,
                                                                "src-placeholder":
                                                                  "@/assets/images/noImage.png"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  )
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form",
                                        {
                                          on: {
                                            submit: function($event) {
                                              $event.preventDefault()
                                              return _vm.handleCheckpointSubmit(
                                                $event
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.checkpointDetailsModal,
                                                expression:
                                                  "checkpointDetailsModal"
                                              }
                                            ],
                                            attrs: {
                                              type: "hidden",
                                              name: "checkpointDetails"
                                            },
                                            domProps: {
                                              value: _vm.checkpointDetailsModal
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.checkpointDetailsModal =
                                                  $event.target.value
                                              }
                                            }
                                          }),
                                          _vm.itemData.status !== "Paused" &&
                                          !_vm.nfcScanned
                                            ? _c(
                                                "div",
                                                { staticClass: "text-center" },
                                                [
                                                  _c("b-img", {
                                                    attrs: {
                                                      src: require("@/assets/images/icons/nfc-scan-animation.gif"),
                                                      width: "75",
                                                      height: "75",
                                                      alt: "Scan NFC Tag"
                                                    }
                                                  }),
                                                  _c(
                                                    "p",
                                                    { staticClass: "mt-1" },
                                                    [
                                                      _vm._v(
                                                        " Please scan the NFC tag "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-form-group",
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: { name: "flagged" },
                                                  model: {
                                                    value:
                                                      _vm.checkpointDetailsModal
                                                        .flagged,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.checkpointDetailsModal,
                                                        "flagged",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "checkpointDetailsModal.flagged"
                                                  }
                                                },
                                                [_vm._v(" Flagged ")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  placeholder: "Enter notes...",
                                                  rows: "3"
                                                },
                                                model: {
                                                  value:
                                                    _vm.checkpointDetailsModal
                                                      .notes,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.checkpointDetailsModal,
                                                      "notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "checkpointDetailsModal.notes"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.checkpointDetailsModal
                                            .show_recheck_button &&
                                          _vm.checkpointDetailsModal
                                            .geo_required
                                            ? _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-alert",
                                                    {
                                                      attrs: {
                                                        variant: "warning",
                                                        show: ""
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-between align-items-center"
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              "You are more than 30 meters from the checkpoint location."
                                                            )
                                                          ]),
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "outline-primary",
                                                                size: "sm"
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.recheckGeolocation
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Recheck Location "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-1 mb-1" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-muted"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "GPS Coordinates: "
                                                      )
                                                    ]
                                                  ),
                                                  _c("small", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm
                                                          .checkpointDetailsModal
                                                          .geo_lat &&
                                                          _vm
                                                            .checkpointDetailsModal
                                                            .geo_long
                                                          ? _vm
                                                              .checkpointDetailsModal
                                                              .geo_lat +
                                                              ", " +
                                                              _vm
                                                                .checkpointDetailsModal
                                                                .geo_long
                                                          : _vm
                                                              .checkpointDetailsModal
                                                              .geo_required
                                                          ? "<span class='text-danger font-weight-bold'>N/A</span>"
                                                          : "N/A"
                                                      )
                                                    }
                                                  }),
                                                  _c("br"),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass: "text-muted"
                                                    },
                                                    [_vm._v("Geo Distance: ")]
                                                  ),
                                                  _c("small", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm
                                                          .checkpointDetailsModal
                                                          .geo_distance &&
                                                          _vm
                                                            .checkpointDetailsModal
                                                            .geo_distance < 30
                                                          ? _vm
                                                              .checkpointDetailsModal
                                                              .geo_distance +
                                                              " m"
                                                          : _vm
                                                              .checkpointDetailsModal
                                                              .geo_required
                                                          ? "<span class='text-danger font-weight-bold'>" +
                                                            _vm
                                                              .checkpointDetailsModal
                                                              .geo_distance +
                                                            " m</span>"
                                                          : "N/A"
                                                      )
                                                    }
                                                  }),
                                                  _c("br")
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-2",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "submit"
                                                      }
                                                    },
                                                    [_vm._v(" Submit ")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                { attrs: { md: "6" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0",
                                                      attrs: {
                                                        variant:
                                                          "outline-secondary",
                                                        block: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$bvModal.hide(
                                                            "checkpoint-modal"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v(" Cancel ")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.itemData &&
                                      _vm.itemData.clientsite !== null &&
                                      _vm.checkpointDetailsModal.name !==
                                        null &&
                                      (_vm.$can(
                                        "create",
                                        _vm.abilityRequired
                                      ) ||
                                        _vm.$can("update", _vm.abilityRequired))
                                        ? _c(
                                            "b-card",
                                            [
                                              _c("b-card-header", [
                                                _c("h5", [
                                                  _vm._v(" Photos / Images ")
                                                ])
                                              ]),
                                              _c("b-card-body", [
                                                _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "b-row",
                                                      { staticClass: "mb-2" },
                                                      [
                                                        _vm.$can(
                                                          "update",
                                                          _vm.abilityRequired
                                                        )
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  md: "2"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1 mb-sm-0",
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      block: "",
                                                                      disabled:
                                                                        _vm.preventDoubleClick
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.openCamera
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Take Picture "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.$can(
                                                          "update",
                                                          _vm.abilityRequired
                                                        )
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  md: "2"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    staticClass:
                                                                      "mb-1 mb-sm-0",
                                                                    attrs: {
                                                                      variant:
                                                                        "primary",
                                                                      block: "",
                                                                      disabled:
                                                                        _vm.preventDoubleClick
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.openImageSelector
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Add Image "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm.docsAttachmentList
                                                  ? _c(
                                                      "section",
                                                      {
                                                        staticClass: "grid-view"
                                                      },
                                                      _vm._l(
                                                        _vm.docsAttachmentList,
                                                        function(mediaItem) {
                                                          return _c(
                                                            "b-card",
                                                            {
                                                              key:
                                                                mediaItem.filename,
                                                              ref:
                                                                mediaItem.filename,
                                                              refInFor: true,
                                                              attrs: {
                                                                id:
                                                                  mediaItem.filename,
                                                                "no-body": ""
                                                              }
                                                            },
                                                            [
                                                              mediaItem.filename.slice(
                                                                -3
                                                              ) === "jpg"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "item-img text-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "thumbs-lazy-image-component",
                                                                        {
                                                                          staticClass:
                                                                            "card-img-top",
                                                                          attrs: {
                                                                            src:
                                                                              mediaItem.access_url,
                                                                            "src-placeholder":
                                                                              "@/assets/images/noImage.png"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              mediaItem.filename.slice(
                                                                -3
                                                              ) === "jpg"
                                                                ? _c(
                                                                    "b-card-footer",
                                                                    {
                                                                      staticClass:
                                                                        "pt-1 pb-1 border-dark"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-row",
                                                                        [
                                                                          _c(
                                                                            "b-col",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                                                  attrs: {
                                                                                    variant:
                                                                                      "primary",
                                                                                    block:
                                                                                      "",
                                                                                    disabled:
                                                                                      _vm.preventDoubleClick
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.deleteImage(
                                                                                        mediaItem
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Delete Image "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c("input", {
                                                  ref: "cameraInput",
                                                  staticStyle: {
                                                    display: "none"
                                                  },
                                                  attrs: {
                                                    type: "file",
                                                    accept: "image/*",
                                                    capture: "environment"
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleImageChange
                                                  }
                                                }),
                                                _c("input", {
                                                  ref: "imageInput",
                                                  staticStyle: {
                                                    display: "none"
                                                  },
                                                  attrs: {
                                                    type: "file",
                                                    accept: "image/*"
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleImageChange
                                                  }
                                                })
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.theCheckpoints
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1 pt-1" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "app-timeline",
                                                _vm._l(
                                                  _vm.theCheckpoints,
                                                  function(checkpoint, index) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: checkpoint.name,
                                                        class: {
                                                          "cursor-pointer":
                                                            _vm.itemData
                                                              .status !==
                                                              "Paused" &&
                                                            ((_vm
                                                              .thePatrolTemplate
                                                              .patrol_in_order &&
                                                              index === 0) ||
                                                              !_vm
                                                                .thePatrolTemplate
                                                                .patrol_in_order),
                                                          "opacity-50":
                                                            _vm.itemData
                                                              .status ===
                                                            "Paused"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleCheckpointClick(
                                                              checkpoint,
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "app-timeline-item",
                                                          {
                                                            attrs: {
                                                              variant: checkpoint.code_scanned
                                                                ? "success"
                                                                : checkpoint.flagged
                                                                ? "danger"
                                                                : "warning",
                                                              icon:
                                                                checkpoint.checkpoint_order ===
                                                                0
                                                                  ? "PauseCircleIcon"
                                                                  : checkpoint.flagged
                                                                  ? "AlertTriangleIcon"
                                                                  : "MapPinIcon"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                                                              },
                                                              [
                                                                _c("h6", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      checkpoint.name
                                                                    )
                                                                  )
                                                                ]),
                                                                checkpoint.point_description
                                                                  ? _c("p", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            checkpoint.point_description
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ])
                                                                  : _vm._e(),
                                                                _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-muted"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "GPS Coordinates: "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c("small", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      checkpoint.geo_lat &&
                                                                        checkpoint.geo_long
                                                                        ? checkpoint.geo_lat +
                                                                            ", " +
                                                                            checkpoint.geo_long
                                                                        : "N/A"
                                                                    )
                                                                  )
                                                                ]),
                                                                _c("br")
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.theCompletedCheckpoints
                                    ? _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 pt-1 border-top-secondary"
                                        },
                                        [
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c("h4", [
                                              _vm._v("Completed Checkpoints")
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.theCompletedCheckpoints
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mt-2 mb-1 pt-1" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "app-timeline",
                                                _vm._l(
                                                  _vm.theCompletedCheckpoints,
                                                  function(checkpoint) {
                                                    return _c(
                                                      "app-timeline-item",
                                                      {
                                                        key: checkpoint.id,
                                                        attrs: {
                                                          variant: checkpoint.code_scanned
                                                            ? "success"
                                                            : checkpoint.flagged
                                                            ? "danger"
                                                            : "warning",
                                                          icon:
                                                            checkpoint.checkpoint_order ===
                                                            0
                                                              ? "PauseCircleIcon"
                                                              : checkpoint.flagged
                                                              ? "AlertTriangleIcon"
                                                              : "MapPinIcon"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0",
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.itemData
                                                                  .status !==
                                                                  "Paused" &&
                                                                _vm.itemData
                                                                  .name !==
                                                                  "Pause"
                                                                  ? _vm.handleCompletedCheckpointClick(
                                                                      checkpoint
                                                                    )
                                                                  : null
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("h6", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  checkpoint.name
                                                                )
                                                              )
                                                            ]),
                                                            _c("small", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  checkpoint.date_time
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _c("p", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              checkpoint.notes
                                                                ? checkpoint.notes.replace(
                                                                    /\n/g,
                                                                    "<br>"
                                                                  )
                                                                : ""
                                                            )
                                                          }
                                                        }),
                                                        _c(
                                                          "p",
                                                          [
                                                            checkpoint.attachment_list
                                                              ? _vm._l(
                                                                  checkpoint.attachment_list,
                                                                  function(
                                                                    attachment
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key:
                                                                          attachment.access_url,
                                                                        staticClass:
                                                                          "item-img text-center"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "thumbs-lazy-image-component",
                                                                          {
                                                                            staticClass:
                                                                              "card-img-top",
                                                                            attrs: {
                                                                              width:
                                                                                "500",
                                                                              src:
                                                                                attachment.access_url,
                                                                              "src-placeholder":
                                                                                "@/assets/images/noImage.png"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                )
                                                              : _vm._e()
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-modal",
                                    {
                                      attrs: {
                                        id: "modal-center",
                                        centered: "",
                                        title: "Vertically Centered",
                                        "ok-only": "",
                                        "ok-title": "Done"
                                      }
                                    },
                                    [
                                      _c(
                                        "draggable",
                                        {
                                          staticClass:
                                            "list-group list-group-flush cursor-move",
                                          attrs: { tag: "ul" },
                                          on: { end: _vm.onReorder },
                                          model: {
                                            value: _vm.itemData.checkpoints,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.itemData,
                                                "checkpoints",
                                                $$v
                                              )
                                            },
                                            expression: "itemData.checkpoints"
                                          }
                                        },
                                        [
                                          _c(
                                            "transition-group",
                                            {
                                              attrs: {
                                                type: "transition",
                                                name: "flip-list"
                                              }
                                            },
                                            _vm._l(
                                              _vm.itemData.checkpoints,
                                              function(listItem) {
                                                return _c(
                                                  "b-list-group-item",
                                                  {
                                                    key: listItem.nfc_tag,
                                                    attrs: { tag: "li" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "ml-25"
                                                          },
                                                          [
                                                            _c(
                                                              "b-card-text",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold mb-0"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      listItem.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.thePatrolTemplate &&
                                  typeof _vm.itemData.status !== "undefined" &&
                                  _vm.itemData.status === "Started"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can(
                                                "create",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "3" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Finalize " +
                                                              _vm._s(
                                                                _vm.itemTypeName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3777371460
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.$globals.isCordova ? _c("div") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }